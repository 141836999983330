import React from "react"
import IntegrationTemplate from "../../templates/integration"
import * as Ui from "components/ui"
import * as Sections from 'components/sections'
import * as Layout from 'components/layout'
import IntegrationStorageContent from 'components/content/integration-storage'
import { meta } from '../../configs/storage-providers';
const ContentPage = () => (
  <IntegrationTemplate
    key="backblaze"
    name="Backblaze B2"
    shortDescription="Store your backups on Backblaze B2."
    website={"https://www.backblaze.com/b2/cloud-storage.html"}
    links={[{text: 'Documentation', url: '/docs'}]}
    category="Storage"
    logo="backblaze-ico.png"
    metaTitle={meta.title.replace('##provider##', 'Backblaze B2 Storage')}
    metaDescription={meta.description.replace('##provider##', 'Backblaze B2 Storage')}
  >
    <IntegrationStorageContent
      keyRef="backblaze"
      service="Backblaze B2 bucket"
      provider="Backblaze"
      learnTags={['backblaze']}
      contentBlocks={[{title: 'Backblaze B2 bucket', text: 'We offer extensive support for Backblaze B2.\n' +
          'B2 legacy mode, and the new Backblaze S3-compliant mode, and the support for master keys & application keys.\n' +
          'Also supports legacy buckets, new buckets, and all B2 regions!'}]}
    />
  </IntegrationTemplate>
)

export default ContentPage
